<template>
  <div class="timeline"
       :class="{
          'day_off': !dayData.is_w_d && !dayData.is_holiday,
          'timeline_is_holiday': dayData.is_holiday,
       }"
       :style="{'z-index': 1131 - dayOfMonth}">
    <div class="day_head" :class="{ 'day_off_day_head': !dayData.is_w_d }">
      <div class="block_with_logos">
        <div v-if="hasLunchTimeNight" class="lunch_block">
          <div class="value_holder">
            обед
          </div>
          <div class="value_holder">
            {{ total_act_l_n_template }}
          </div>
        </div>
        <div v-if="hasLunchTimeDay && (isFreeHoursVisible || isActualDayHoursVisible || isActualNightHoursVisible)" class="lunch_block">
          <div class="value_holder">обед: {{ total_act_l_d_template }} ч</div>
        </div>
      </div>
      <div class="number_day_of_month"
           :class="{
              'number_day_of_month_holiday': dayData.is_holiday
      }"
      >
        {{ dayOfMonth }}
      </div>
    </div>
    <div class="act_work_time_track">
      <!--      FREE SHIFT-->
      <div class="act_work_time act_work_time_free_shift"
           v-if="isFreeHoursVisible"
           :style="{
             width: free_shift_width + '%',
             left: free_shift_left + '%'
           }"
      >
        <div class="pseudo_act_work_time">
          <div class="act_work_time_content">
            <div class="total_act_work_time_template">
              {{ total_free_shift_in_template }}
            </div>
            <div class="free_shift_time_announce_span">{{ freeShiftFromToValues }}</div>
          </div>
        </div>
      </div>
      <!--      DAY-->
      <div class="act_work_time"
           v-if="isActualDayHoursVisible"
           :class="{
              'act_work_time_norm_completed': isDayShiftNormCompleted && dayData.is_w_d,
              'act_work_time_norm_not_completed': !isDayShiftNormCompleted && dayData.is_w_d,
              'act_work_time_without_schedule' : !isScheduleDayHoursVisible || !isScheduleNightHoursVisible && isActualNightHoursVisible
               }"
           :style="{
             width: act_w_d_width + '%',
             left: act_w_d_left + '%'
           }"
      >
        <div class="pseudo_act_work_time">
          <div class="act_work_time_content">
            <div class="total_act_work_time_template">
              {{ total_act_w_d_template }}
            </div>
            <div class="act_work_time_announce_span">{{ actualWorkTimeDayFromToValues }}</div>
          </div>
        </div>
      </div>
      <!--      NIGHT-->
      <div class="act_work_time"
           v-if="isActualNightHoursVisible"
           :class="{
              'act_work_time_norm_completed': isNightShiftNormCompleted && dayData.is_w_d,
              'act_work_time_norm_not_completed': !isNightShiftNormCompleted && dayData.is_w_d,
              'act_work_time_without_schedule' : !isScheduleNightHoursVisible && isActualNightHoursVisible
               }"
           :style="{
             width: act_w_n_width + '%',
             left: act_w_n_left + '%'
           }"
      >
        <div class="pseudo_act_work_time">
          <div class="act_work_time_content">
            <div class="total_act_work_time_template">
              {{ total_act_w_n_template }}
            </div>
            <div class="act_work_time_announce_span">{{ actualWorkTimeNightFromToValues }}</div>
          </div>
        </div>
      </div>
    </div>
    <!--NIGHT END-->
    <!--DAY-->
    <div class="schedule_w_track">
      <div class="schedule_work_time schedule_work_time_background"
           v-if="isScheduleNightHoursVisible && !dayData.is_holiday"
           :style="{
             width: sch_w_n_width + '%',
             left: sch_w_n_left + '%'
           }"
      >
        <div class="pseudo_schedule_work_time">
          <div class="schedule_work_time_content">
            <div class="total_schedule_work_time_template">
              {{ total_sch_w_n_template }}
            </div>
            <div class="schedule_work_time_announce_span">{{ scheduleWorkTimeNightFromToValues }}</div>
          </div>
        </div>
      </div>
      <div class="schedule_work_time schedule_work_time_background"
           v-if="isScheduleDayHoursVisible && !dayData.is_holiday"
           :style="{
             width: sch_w_d_width + '%',
             left: sch_w_d_left + '%'
           }"
      >
        <div class="pseudo_schedule_work_time">
          <div class="schedule_work_time_content">
            <div class="total_schedule_work_time_template">
              {{ total_sch_w_d_template }}
            </div>
            <div class="schedule_work_time_announce_span">{{ scheduleWorkTimeDayFromToValues }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import LunchSvg from "./lunch-svg.vue";

export default {
  name: "crm-new-calendar-day",
  components: {LunchSvg},
  props: {
    dayData: {
      type: Object,
    }
  },
  methods: {
    parseTimeFromDecimalToHm(timeInDecimalHm) {
      const hours = Math.floor(timeInDecimalHm);
      const minutesDecimal = (timeInDecimalHm - hours) * 60;
      const minutes = Math.round(minutesDecimal);
      return minutes === 0 ? `${hours}` : `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;
    },
    parseTime(timeString) {
      if (timeString !== null) {
        const timePart = timeString.split(" ")[1];
        const [hours, minutes, seconds] = timePart.split(":");
        return new Date(0, 0, 0, hours, minutes, seconds)
      } else {
        return null
      }

    },
    formatTimeRange(startTime, endTime) {
      const format = (timestamp) => {
        if (timestamp !== null) {
          const [date, time] = timestamp.split(' ');
          const [hours, minutes] = time.split(':');
          const formattedHours = parseInt(hours).toString();
          return `${formattedHours}:${minutes || '00'}`;
        }
      };
      return `${format(startTime)} - ${format(endTime)}`;
    },
    actualDayHoursVisible(dayData) {
      const {
        start_act_w_d,
        end_act_w_d,
      } = dayData;
      return start_act_w_d && end_act_w_d
    },
    freeShiftVisible(dayData) {
      const {
        free_shift_info,
      } = dayData;
      return free_shift_info !== null;
    },
    scheduleDayHoursVisible(dayData) {
      const {
        start_sch_w_d,
        end_sch_w_d
      } = dayData;
      return start_sch_w_d && end_sch_w_d
    },
    actualNightHoursVisible(dayData) {
      const {
        start_act_w_n,
        end_act_w_n,
      } = dayData;
      return start_act_w_n && end_act_w_n;
    },
    scheduleNightHoursVisible(dayData) {
      const {
        start_sch_w_n,
        end_sch_w_n,
      } = dayData;
      return start_sch_w_n && end_sch_w_n;
    },
  },
  computed: {
    hasLunchTimeDay() {
      return this.dayData.total_act_l_d > 0 || this.dayData.total_sch_w_d > 0;
    },
    hasLunchTimeNight() {
      return this.dayData.total_act_l_n > 0 || this.dayData.total_sch_w_n > 0;
    },
    isNightShiftNormCompleted() {
      if (this.isActualNightHoursVisible && this.isScheduleNightHoursVisible) {
        const totalActualWorkTimeNight = parseFloat(this.dayData.total_act_w_n);
        const totalScheduleWorkTimeNight = parseFloat(this.dayData.total_sch_w_n);
        return totalActualWorkTimeNight >= totalScheduleWorkTimeNight;
      }
    },
    isDayShiftNormCompleted() {
      if (this.isActualDayHoursVisible && this.isScheduleDayHoursVisible) {
        const totalActualWorkTimeDay = parseFloat(this.dayData.total_act_w_d);
        const totalScheduleWorkTimeDay = parseFloat(this.dayData.total_sch_w_d);
        return totalActualWorkTimeDay >= totalScheduleWorkTimeDay;
      }
    },
    freeShiftFromToValues(){
      return this.formatTimeRange(this.dayData.free_shift_info.start_free_shift, this.dayData.free_shift_info.end_free_shift);
    },
    scheduleWorkTimeDayFromToValues() {
      return this.formatTimeRange(this.dayData.start_sch_w_d, this.dayData.end_sch_w_d);
    },
    actualWorkTimeDayFromToValues() {
      return this.formatTimeRange(this.dayData.start_act_w_d, this.dayData.end_act_w_d);
    },
    scheduleWorkTimeNightFromToValues() {
      return this.formatTimeRange(this.dayData.start_sch_w_n, this.dayData.end_sch_w_n);
    },
    actualWorkTimeNightFromToValues() {
      return this.formatTimeRange(this.dayData.start_act_w_n, this.dayData.end_act_w_n);
    },
    dayOfMonth() {
      const date = new Date(this.dayData.date);
      return date.getDate();
    },
    isFreeHoursVisible(){
      return this.freeShiftVisible(this.dayData)
    },
    isActualDayHoursVisible() {
      return this.actualDayHoursVisible(this.dayData);
    },
    isScheduleDayHoursVisible() {
      return this.scheduleDayHoursVisible(this.dayData);
    },
    isActualNightHoursVisible() {
      return this.actualNightHoursVisible(this.dayData);
    },
    isScheduleNightHoursVisible() {
      return this.scheduleNightHoursVisible(this.dayData);
    },
    total_free_shift_in_template() {
      return this.parseTimeFromDecimalToHm(this.dayData.free_shift_info.total_free_shift);
    },
    total_act_l_n_template() {
      return this.hasLunchTimeNight ? this.parseTimeFromDecimalToHm(this.dayData.total_act_l_n) : false;
    },
    total_act_l_d_template() {
      return this.hasLunchTimeDay ? this.parseTimeFromDecimalToHm(this.dayData.total_act_l_d) : false;
    },
    total_act_w_d_template() {
      return this.isActualDayHoursVisible ? this.parseTimeFromDecimalToHm(this.dayData.total_act_w_d) : false;
    },
    total_sch_w_d_template() {
      return this.isScheduleDayHoursVisible ? this.parseTimeFromDecimalToHm(this.dayData.total_sch_w_d) : false;
    },
    total_act_w_n_template() {
      return this.isActualNightHoursVisible ? this.parseTimeFromDecimalToHm(this.dayData.total_act_w_n) : false;
    },
    total_sch_w_n_template() {
      return this.isScheduleNightHoursVisible ? this.parseTimeFromDecimalToHm(this.dayData.total_sch_w_n) : false;
    },
    free_shift_left() {
      if (!this.isFreeHoursVisible) {
        return false;
      } else {
        const {free_shift_info} = this.dayData;
        const [start] = [this.parseTime(free_shift_info.start_free_shift)];
        const startHour = start.getHours() + start.getMinutes() / 60;
        if (this.dayData.day_of_week === 7) {
          return startHour / 24 * 100
        }
        return startHour / 24 * 100;
      }
    },
    free_shift_width() {
      if (!this.isFreeHoursVisible) {
        return false;
      } else {
        const {free_shift_info} = this.dayData;
        const percentageOfDay = (new Date(free_shift_info.end_free_shift) - new Date(free_shift_info.start_free_shift)) / (240 * 60 * 60);
        if (this.dayData.day_of_week === 7) {
          return Math.round(percentageOfDay * 100) / 100 - 50;
        }
        return Math.round(percentageOfDay * 100) / 100;

      }
    },
    act_w_d_left() {
      if (!this.isActualDayHoursVisible) {
        return false;
      } else {
        const {start_act_w_d} = this.dayData;
        const [start] = [this.parseTime(start_act_w_d)];
        const startHour = start.getHours() + start.getMinutes() / 60;
        return startHour / 24 * 100;
      }
    },
    act_w_d_width() {
      if (!this.isActualDayHoursVisible) {
        return false;
      } else {
        const {start_act_w_d, end_act_w_d} = this.dayData;
        const percentageOfDay = (new Date(end_act_w_d) - new Date(start_act_w_d)) / (240 * 60 * 60);
        return Math.round(percentageOfDay * 100) / 100;
      }
    },
    act_w_n_left() {
      if (!this.isActualNightHoursVisible) {
        return false;
      } else {
        const {start_act_w_n} = this.dayData;
        const [start] = [this.parseTime(start_act_w_n)];
        const startHour = start.getHours() + start.getMinutes() / 60;
        if (this.dayData.day_of_week === 7) {
          return startHour / 24 * 100 - 58
        }
        return startHour / 24 * 100;
      }
    },
    act_w_n_width() {
      if (!this.isActualNightHoursVisible) {
        return false;
      } else {
        const {start_act_w_n, end_act_w_n} = this.dayData;
        const percentageOfDay = (new Date(end_act_w_n) - new Date(start_act_w_n)) / (240 * 60 * 60);
        return Math.round(percentageOfDay * 100) / 100;
      }
    },
    sch_w_d_width() {
      if (!this.isScheduleDayHoursVisible) {
        return false;
      } else {
        const {start_sch_w_d, end_sch_w_d} = this.dayData;
        const percentageOfDay = (new Date(end_sch_w_d) - new Date(start_sch_w_d)) / (240 * 60 * 60);
        return Math.round(percentageOfDay * 100) / 100;
      }
    },
    sch_w_n_width() {
      if (!this.isScheduleNightHoursVisible) {
        return false;
      } else {
        const {start_sch_w_n, end_sch_w_n} = this.dayData;
        const percentageOfDay = (new Date(end_sch_w_n) - new Date(start_sch_w_n)) / (240 * 60 * 60);
        return Math.round(percentageOfDay * 100) / 100;
      }
    },
    sch_w_d_left() {
      if (!this.isScheduleDayHoursVisible) {
        return false;
      } else {
        const {start_sch_w_d} = this.dayData;
        const [start] = [this.parseTime(start_sch_w_d)];
        const startHour = start.getHours() + start.getMinutes() / 60;
        return startHour / 24 * 100;
      }
    },
    sch_w_n_left() {
      if (!this.isScheduleNightHoursVisible) {
        return false;
      } else {
        const {start_sch_w_n} = this.dayData;
        const [start] = [this.parseTime(start_sch_w_n)];
        const startHour = start.getHours() + start.getMinutes() / 60;
        if (this.dayData.day_of_week === 7) {
          return startHour / 24 * 100 - 70
        }
        return startHour / 24 * 100;
      }
    }
  }
};
</script>
<style lang="css">
.timeline {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: visible;
  height: 100%;
  width: 100%;
  padding: 2%;
}

.timeline_is_holiday {
  background: #f9dcea;
}

.act_work_time_norm_not_completed {
  color: #ea913b;
  background: #f7d7b6;
}

.act_work_time_free_shift {
  color: #53008e;
  background: #e8c9ff;
}

.act_work_time_without_schedule {
  color: #8676f1;
  background: #d2d7fa;
}

.act_work_time_norm_completed {
  color: #6aad45;
  background: #d0e6c3;
}

.number_day_of_month_holiday {
  color: #ed96bc !important;
}

.day_off {
  background: #d8d8d8;
}

.day_head {
  display: flex;
  justify-content: space-between;
  height: min-content
}

.act_work_time_track {
  overflow: visible;
  height: 24%;
  width: 100%;
  position: absolute;
  bottom: 32%;
  left: 0;
}

.schedule_w_track {
  overflow: visible;
  height: 24%;
  width: 100%;
  position: absolute;
  bottom: 4%;
  left: 0
}

.act_work_time,
.schedule_work_time {
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  overflow: hidden;
  border-radius: 14px;
  padding-right: 6px;
  min-width: 2.56rem !important;
  transition: width 0.5s;
}

.schedule_work_time_background {
  color: #617072;
  background: #bec7c8
}

.pseudo_act_work_time,
.pseudo_schedule_work_time {
  overflow: hidden;
}

.act_work_time_content,
.schedule_work_time_content {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin-left: 6px;
}

.act_work_time_announce_span,
.free_shift_time_announce_span,
.schedule_work_time_announce_span {
  font-size: 0.9rem;
  font-weight: 900;
  white-space: nowrap;
  margin: auto 0 auto 8px;;
}

.total_act_work_time_template,
.total_schedule_work_time_template {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 900;
  color: black;
  margin: auto 0;
}

.pseudo_schedule_work_time:hover,
.pseudo_act_work_time:hover {
  cursor: pointer;
}

.act_work_time:hover,
.schedule_work_time:hover {
  cursor: pointer;
  transition: width 5s;
  width: min-content !important;
}

.number_day_of_month {
  font-weight: 900;
  font-size: 0.9rem;
  color: #656565;
}

.block_with_logos {
  color: black;
  display: flex;
  flex-direction: row;
}

.lunch_block {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 0.6rem;
  font-weight: 600;
}

.value_holder {
  margin: auto;
}
</style>
// super color #5A425C;



